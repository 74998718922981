.sis-shop-service {
  margin-bottom: 1em;
}

.sis-shop-service__info {
  display: flex;

  vaadin-form-layout {
    width: 70%;
  }

  .sis-image {
    width: 30%;
  }
}

.sis-oh-services__create-button {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  cursor: pointer;
}

.sis-shop-service__opening-hours {
  margin-top: 2em;
}