/* ICONS */
vaadin-grid iron-icon {
  width: 15px;
  height: 15px;
}
.sis-icon-edit,
#icon-edit,
#icon-action,
.sis-icon-delete,
#icon-id-edit,
#icon-id-delete {
  color: $color-dark-background;
}
#icon-id-edit,
#icon-id-delete {
  padding-left: 0.5em;
}

.sis-icon-user--disabled,
.sis-icon-delete,
#icon-error {
  color: $color-error-color;
}

.sis-icon-user--actived,
#icon-success {
  color: $color-success-color;
}

#icon-id-edit:hover {
  color: $color-edit-hover;
}

#icon-id-delete:hover {
  color: $color-delete-hover;
}
