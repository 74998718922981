vaadin-dialog-overlay {
  p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0.2em;
  }
}
