.sis-search__container {
  width: 100%;
  margin: -1.5em -1.5em 0 -1.5em;
  padding: 0em 1.5em 0.15em 0em;
  border-radius: 0.15em 0em 0 0;
  background: $color-card-background;
  color: $color-card-font;
}

#sis_search__form {
}

#searchBar {
  width: 100%;
}

.sis-search__info-heading {
  margin-bottom: 0.5em;
}

.sis-search__input {
  margin: 1em;
}

.sis-search__listbox {
}

.sis-search__button {
  margin: 1em;
}

.sis-search-reset-container {
  display: flex;
  justify-content: flex-end;
}
.sis-search__reset-button {
  background: $color-success-color;
  color: $color-success-contrast-color;
}
@media only screen and (max-width: 600px) {
  .sis-search__reset-button {
    width: 100%;
  }
}
