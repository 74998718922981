html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $color-background;
  font-family: var(--lumo-font-family);
}

a {
  color: $color-card-font;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

vaadin-checkbox {
  white-space: nowrap;
}

.primary {
  color: $color-primary-color;
}

.active {
  color: $color-primary-color;
}
