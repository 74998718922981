.sis-login__container {
}

.sis-login__heading {
  margin: -1.5em -1.5em 0 -1.5em;
  padding: 1.5em 1.5em 0.15em 1.5em;
  border-radius: 0.15em 0.15em 0 0;
  background: $color-primary-color;
  color: $color-primary-contrast-color;
}
.sis-login__heading h1 {
  margin-bottom: 0.2em;
}
.sis-login__heading p {
  margin-top: 0em;
  margin-bottom: 0.5em;
}

.sis-login__info-heading {
  margin-bottom: 0.5em;
}

.sis-login__input {
  width: 100%;
}

.sis-login__button {
  width: 100%;
}
