vaadin-grid {
  border-top: none;
}

#Shops-list #icon-action {
  padding-left: 0.5em;
}
vaadin-grid-sort-column:hover,
vaadin-grid-column:hover,
vaadin-grid-cell-content:hover {
  cursor: pointer;
}

/* Grid header (in shadow dom) */
vaadin-grid::part(header-cell) {
  background: $color-grid-header;
}
vaadin-grid::part(header-cell):hover {
  cursor: default;
}

#Shops-list > vaadin-grid-cell-content {
  // text-overflow: clip; /* this works... */
}

#Shops-list > :not(vaadin-grid-cell-content) > iron-icon {
}

#Shops-list > vaadin-grid-cell-content iron-icon {
}

#Shops-list
  > vaadin-grid-cell-content[slot="vaadin-grid-cell-content-0"]
  vaadin-grid-sorter::part(indicators) {
  margin-left: 0px;
  margin-top: -15px;
}

#root > div > vaadin-app-layout > div > vaadin-vertical-layout > span {
  margin-bottom: 0.5em;
}

.sis-grid__container {
  width: 100%;
}

.sis-smallText {
  font-size: 0.6em;
}
