$color-background: #fafbfe;
$color-backdrop: #a5b3c4;

$color-dark-background: #313a46;
$color-grey-foreground: #98a6ad;

/* Primary */
$color-primary-color: hsl(214, 90%, 52%);
$color-primary-color-50pct: hsla(214, 90%, 52%, 0.5);
$color-primary-color-10pct: hsla(214, 90%, 52%, 0.1);
$color-primary-text-color: $color-primary-color;
$color-primary-contrast-color: #fff;

/* Error */
$color-error-color: hsl(3, 100%, 61%);
$color-error-color-50pct: hsla(3, 100%, 60%, 0.5);
$color-error-color-10pct: hsla(3, 100%, 60%, 0.1);
$color-error-text-color: hsl(3, 92%, 53%);
$color-error-contrast-color: #fff;

/* Warning */
$color-warning-color: hsl(38, 100%, 61%);
$color-warning-color-50pct: hsla(38, 100%, 61%, 0.5);
$color-warning-color-10pct: hsla(38, 100%, 61%, 0.1);
$color-warning-text-color: hsl(38, 92%, 53%);
$color-warning-contrast-color: #fff;

/* Success */
$color-success-color: hsl(145, 80%, 42%);
$color-success-color-50pct: hsla(145, 76%, 44%, 0.55);
$color-success-color-10pct: hsla(145, 76%, 44%, 0.12);
$color-success-text-color: hsl(145, 100%, 32%);
$color-success-contrast-color: #fff;

/* Container */
$color-container-primary-background: #fff;
$color-container-primary-color: #000;

/* override primary */
$color-primary-color: #9b0ae3;
$notification-color: #0099ff;

/* Grid */
$color-grid-header: rgba(231, 233, 237, 0.9);

$color-card-font: #6c757d;
$color-card-background: #ffffff;

/* Hover Colors */
$color-edit-hover: #ff9500;
$color-delete-hover: $color-error-color;

/* Where does this come from */
/* From nowhere... well... from Apples own color picker... just wanted to match the grid header with input bg but failed... */
/* @TODO: FIX THIS */
$color-input-background: #ebedf0;
