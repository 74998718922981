.sis-message--error {
  // background: $notification-color;
  background: $color-primary-color;
  color: $color-primary-contrast-color;
  width: 100%;
}

.sis-message--error--message {
  padding-left: 1.7em;
}

.sis-message--loading {
  background: $notification-color;
  color: $color-primary-contrast-color;
}

.sis-message--error iron-icon {
  position: relative;
  margin-bottom: -15px;
  margin-left: -5px;
  padding-right: 4px;
}
