.sis-location__map {
  // margin-left: 1.5em;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}

/* google map api wrapper fix */
.map-fix-div-wrapper > div {
  width: 100%;
  height: 100%;
}

.sis-loading__overlay {
  background: $color-grid-header;
  color: $color-success-contrast-color;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
