.sis-image {
  height: 100%;
  box-sizing: border-box;
  // margin-left: 1.5em;
  transition: all 0.3s;
}

.sis-image--has-image {
  background-color: $color-input-background;
  border-radius: 3px;
}

.sis-image__image--no-image {
  border: 1px dashed var(--lumo-contrast-20pct);
  border-radius: var(--lumo-border-radius);
  position: relative;
}

.sis-image__image--no-image__text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  color: var(--lumo-secondary-text-color);
  font-weight: 300;
}

.sis-image__upload {
  height: 100%;
  box-sizing: border-box;
}

.sis-image__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: relative;
}

.sis-image__image-remove {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  opacity: 0.75;
  color: $color-primary-contrast-color;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before {
    content: "";
    width: 80%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    border-radius: 100%;
    background: $color-error-text-color;
    box-shadow: 1px 1px 4px 1px $color-backdrop;
  }
}
