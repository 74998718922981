.sis-app {
}

.sis-app-content {
  padding: 1.5em;
}

.sis-details--heading {
  width: 100%;
  justify-content: space-between;
}

.sis-icon--spinning {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  justify-content: space-between;
}
.column {
  flex: 49%;
  max-width: 49%;
  padding: 0 0;
}
.column-2 {
  flex: 49%;
  max-width: 49%;
  padding: 0 0;
}
.column-3 {
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 0;
}
.column-4 {
  flex: 25%;
  max-width: 25%;
  padding: 0 0;
}

@media (max-width: 1366px) {
  .xl-breakpoint .column-2 {
    flex: 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .column,
  .column-2,
  .column-3,
  .column-4 {
    flex: 100%;
    max-width: 100%;
  }

  /* additional div that must have some height to look better in mobile */
  .additional-div {
    min-height: 450px;
    margin-top: 4%;
  }

  .row vaadin-checkbox {
    padding-top: 4%;
    padding-bottom: 4%;
  }
  vaadin-form-layout > vaadin-checkbox {
    padding-top: 4%;
    padding-bottom: 4%;
  }
}

.loading-dots {
  font-size: 30px;
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.sis-container {
  background-color: $color-container-primary-background;
  color: $color-container-primary-color;
  padding: 1.5em;
  margin-bottom: 1.5em;
  width: 100%;
  box-sizing: border-box;
  box-shadow: $color-backdrop 0px 2px 5px -1px;
}

.sis-container--success {
  background-color: $color-success-color;
  color: $color-success-contrast-color;
}

.sis-container--error {
  background-color: $color-error-color;
  color: $color-error-contrast-color;
}

.sis-container--warning {
  background-color: $color-warning-color;
  color: $color-warning-contrast-color;
}

.sis-container__title {
  margin-bottom: 0.3em;
}

.sis-container--inner__title {
  margin-bottom: 0.3em;
  margin-top: 0;
}

.sis-container--inner {
  background-color: darken($color-container-primary-background, 5%);
  border-radius: 3px;
  padding: 1.5em;
  box-sizing: border-box;
}

.sis-container--inner .sis-container--inner {
  background-color: darken($color-container-primary-background, 10%);
}

.sis-container--fullsize {
  width: 100%;
  height: 100%;
}

.sis-center {
  text-align: center;
}

.sis-h-layout--fwc {
  display: flex;
  flex-direction: row;

  &.sis-h-layout--fwc--break-xl {
    @media screen and (max-width: 1550px) {
      flex-direction: column;

      & > * {
        margin-bottom: 2em;
      }
    }
  }

  & > * {
    width: 100%;
  }
}

.sis-v-layout--space-between {
  justify-content: space-between;
  height: 100%;
}

/* use for img etc in Horizontal Layout */
.block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sis-oh-exceptions {
  // margin-left: 1.5em;
  width: 100%;
  height: 100%;
}

.sis-oh-exceptions__create-button {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  cursor: pointer;
}

.sis-oh-exceptions__exception {
  position: relative;
}

.sis-oh-exceptions__exception__delete {
  position: absolute;
  right: 0;
  bottom: 0.75em;
  opacity: 0.5;
  transition: all 0.5s;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

/* mobile fixes */
#mobile-header {
  padding-top: 1em;
  width: 100%;
}

.sis-navbar {
  background: $color-container-primary-background;
  color: $color-grey-foreground;
  min-height: 54px;
}

.sis-logo {
  background: $color-container-primary-background;
  padding-left: 1em;
}

.sis-infotext__no-results {
  font-weight: 300;
  color: var(--lumo-secondary-text-color);
  text-align: center;
  margin-top: 2em;
}

.sis-abs-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
}
