.sis-navbar .sis-avatar-menu {
  margin-left: auto;
  /* padding-right: 2em; */
  padding-right: 0;
}

@media only screen and (max-width: 600px) {
  /* THIS IS ONLY A QUICK FIX TODO the image element must be changed */
  .sis-navbar .sis-avatar-menu {
    position: relative;
    margin-right: 0px;
    padding-right: 0em;
  }

  .sis-logo {
    max-width: 48px;
    height: 27px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media only screen and (max-width: 320px) {
  .sis-navbar .sis-avatar-menu {
    position: relative;
    margin-left: -28px;
    padding-right: 0em;
  }
}

.sis-avatar-menu vaadin-item > * {
  background: $color-container-primary-background;
}

.sis-avatar-menu .sis-container {
  display: none;
}

#root
  > div
  > vaadin-app-layout
  > vaadin-horizontal-layout
  > vaadin-tab.sis-avatar-menu
  > vaadin-item
  > vaadin-select,
#root
  > div
  > vaadin-app-layout
  > vaadin-horizontal-layout
  > vaadin-tab.sis-avatar-menu
  > vaadin-item {
  background: $color-container-primary-background;
}

/* custom avatar-menu buttons */

.sis-avatar-menu-icon {
  // background: $color-container-primary-background;
  width: 15px;
  height: 15px;
  color: $color-primary-color;
  font-size: 0.7em;
}

.sis-avatar-menu-text {
  color: $color-primary-color;
  padding-left: 0.65em;
  font-size: 0.7em;
}

/* Loading */
.sis-button-loading-avatarMenu {
  text-align: center;
  color: $color-primary-color;
}
.sis-button-loading-avatarMenu .loading-dots {
  margin-top: 0.3em;
  color: $color-primary-color;
}
